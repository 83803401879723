@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;1,300&family=Montserrat:wght@200;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;1,300&family=Montserrat:wght@200;400;500;700&family=Sofia&display=swap");
:root {
  --primary: #c36bd6;
  --secondary: #583fbb;
  --dark: #22252d;
  --light: #ffffff;
  --lightPrimary: #c36bd61a;
}

* {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  width: 4px;
  border-radius: 2rem;
  background-color: var(--primary);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 2rem;
}

a {
  text-decoration: none;
  color: inherit;
  font: inherit;
}

li {
  list-style: none;
}

img {
  max-width: 100%;
}

html {
  scroll-behavior: smooth;
  user-select: none;
}

body {
  height: 100vh;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
  background: var(--dark);
  color: #ffffff;
}

img {
  height: "auto";
}

.container {
  width: 100%;
  max-width: 1240px;
  margin: 3.5rem auto;
}

.slider {
  padding-bottom: 30px;
  position: relative;
}

.react-multi-carousel-list {
  width: "100%";
  margin: "0 auto";
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.slider .react-multi-carousel-dot button {
  background: var(--primary);
  width: 1rem;
  height: 1rem;
  border: none;
  border-radius: 50%;
}

.slider .react-multi-carousel-dot--active button {
  background: var(--secondary);
  width: 2rem;
  border-radius: 0.5rem;
}

.banner {
  background: linear-gradient(
    199deg,
    rgba(88, 63, 187, 0.1) -17%,
    rgba(195, 107, 214, 0.1) 102.05%
  );
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px 25px;
  border-radius: 44px;
  transition: all 0.5s ease-in-out;
}

.banner:hover {
  box-shadow: 6px 6px 21px 0px rgba(136, 83, 199, 0.4);
}

@keyframes image-list-animation {
  0% {
    transform: translateY(-566px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes image-list-reverse-animation {
  100% {
    transform: translateY(-566px);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(-2px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateY(2px);
  }
}

@keyframes bounceReverse {
  0%,
  100% {
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(2px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateY(-2px);
  }
}

@media only screen and (max-width: 600px) {
  .banner {
    padding: 0;
    border-radius: 36px;
    border: none;
  }
  .container {
    margin: 30px auto;
  }
}
